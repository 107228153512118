/* Set the background color for the entire page */
.maintenance-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: #143048; /* Deep blue background */
    padding: 20px;
    color: #fff; /* White text to contrast with the dark background */
  }
  
  /* Style for the content container */
  .maintenance-content {
    max-width: 500px;
    padding: 40px;
    background: rgba(255, 255, 255, 0.8); /* Light white background with slight opacity */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  /* Heading styles */
  h1 {
    font-size: 2.5em;
    color: #143048; /* Dark blue for the heading */
    margin-bottom: 20px;
  }
  
  /* Paragraph text */
  p {
    color: #333; /* Dark grey text for better readability */
    font-size: 1.1em;
    line-height: 1.5;
    margin-bottom: 30px;
  }
  
  /* Contact info styles */
  .contact-info {
    color: #fff; /* White color for the contact info text */
    font-size: 1em;
    margin-bottom: 30px;
  }
  
  .contact-info a {
    color: #143048; /* A bright color for the email link */
    text-decoration: none;
  }
  
  .contact-info a:hover {
    text-decoration: underline;
  }
  
  /* Social media icons styles */
  .social-icons a {
    color: #fff; /* White icons */
    font-size: 2.5em;
    margin: 0 15px;
    transition: color 0.3s ease;
  }
  
  .social-icons a:hover {
    color: #143048; /* Bright gold color on hover */
  }
  
  /* Logo styling */
  .company-logo {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .company-logo .logo {
    width:180px;
    height: auto;
  }
  